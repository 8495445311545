import { observer } from 'mobx-react-lite';
import { useState, useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import { taskStore } from '@/state/TaskStore';
import { teamMateStore } from '@/state/TeammateStore';
import { Task } from '@/types/task.types';
import { TeamMate } from '@/types/teammate.types';
import ProfileTab from '../ProfileTab';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';

type TaskAssignDropdownProps = {
  task: Task;
  handleOnSelect: (userId: string | null) => void;
};

export const TaskAssignDropdown = observer(
  ({ task, handleOnSelect }: TaskAssignDropdownProps) => {
    const [assignedUsers, setAssignedUsers] = useState<TeamMate[]>(
      teamMateStore?.teamMates,
    );

    const handleAssigneeFilter = useCallback((e: any) => {
      const typedValue = e.target.value;
      const filteredUsers = teamMateStore?.teamMates?.filter(teamMates =>
        teamMates?.user?.user_name
          ?.toLowerCase()
          ?.includes(typedValue?.toLowerCase()),
      );
      setAssignedUsers(filteredUsers);
    }, []);

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className='flex-1 text-left w-full cursor-pointer'
            onMouseEnter={() => taskStore?.setSelectedTask(task)}
          >
            <p className='hover:text-gray-400 transition-colors'>
              {task?.assignedUser ? (
                <ProfileTab
                  imageURL={task?.assignedUser?.profile_picture_url}
                  name={task?.assignedUser?.user_name}
                />
              ) : (
                'Unassigned'
              )}
            </p>
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent align='start' className='w-52 gap-y-2'>
          <input
            type='text'
            placeholder='Search for assignee'
            onChange={handleAssigneeFilter}
            className='border-b p-2 w-full outline-none'
            autoFocus
          />

          {assignedUsers?.length ? (
            assignedUsers?.map(item => (
              <DropdownMenuItem
                key={item?._id}
                className='flex items-center justify-between cursor-pointer text-md'
                onClick={e => {
                  e.stopPropagation();
                  handleOnSelect(item?.user?._id as string);
                }}
              >
                <span>{item?.user?.user_name}</span>
                {task?.assignedUser && (
                  <button
                    className='p-0.5 bg-red-500 rounded-full flex items-center justify-center'
                    onClick={e => {
                      handleOnSelect(null);
                      e.stopPropagation();
                    }}
                  >
                    <MdClose className='text-white' size={15} />
                  </button>
                )}
              </DropdownMenuItem>
            ))
          ) : (
            <div className='p-2 text-gray-400'>No teammate found</div>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  },
);
