import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import Setup from './Setup';
import Services from './Services';

export default function Integrations() {
  return (
    <div className='min-h-screen overflow-y-auto bg-white mb-12'>
      <div className='w-full px-16 mx-auto  py-6 space-y-8'>
        <div className='space-y-0.5'>
          <h2 className='text-2xl font-bold tracking-tight'>Integrations</h2>
          <p className='text-muted-foreground'>
            Manage your integrations and email settings
          </p>
        </div>

        <Tabs defaultValue='integrations' className='space-y-6'>
          <TabsList>
            <TabsTrigger value='integrations' className='text-md'>
              Service Integrations
            </TabsTrigger>
            <TabsTrigger value='email' className='text-md'>
              Email Setup
            </TabsTrigger>
          </TabsList>

          <TabsContent value='integrations' className='space-y-4'>
            <Services />
          </TabsContent>

          <TabsContent value='email' className='space-y-6'>
            <Setup />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
