/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import EmptyList from '@/components/atoms/EmptyList';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   CardTitle,
//   CardFooter,
// } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  ArrowUpDown,
  BarChart,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Edit,
  Filter,
  Mail,
  Menu,
  MoreVertical,
  Search,
  Settings,
  Trash,
  X,
  Copy,
  Bell,
  MessageSquare,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ScrollArea } from '@/components/ui/scroll-area';
import { observer } from 'mobx-react-lite';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import Spinner from '@/components/atoms/Spinner';
import { notify } from '@/helpers/index';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { useList } from '@/hooks/useList';
import {
  EmailCampaignFilter,
  emailMarketingStore,
} from '@/state/EmailMarketingStore';
import { useNavigate } from 'react-router-dom';
import routeNames from '@/routes/routeNames';
import { replaceRouteParam } from '@/utils/routes';
import { AuthStore } from '@/state/AuthenticationStore';
import { SelectSingleEventHandler } from 'react-day-picker';
import { useDebounce } from '@/hooks/useDebounce';
import CampaignCalendar from './CampaignCalendar';
import { listStore } from '@/state/ListStore';
import { useInView } from 'react-intersection-observer';

type Campaign = {
  id: string;
  title: string;
  status: 'draft' | 'scheduled' | 'sent';
  createdDate: string;
  sendAt: string;
  lastEdited: string;
  delivered: number;
  sentCount: number;
  openCount: number;
  clickCount: number;
  type: string;
  draftDate: Date;
  scheduledDate?: string;
  templateId: string;
};

type SortConfig = {
  key: keyof Campaign;
  direction: 'asc' | 'desc';
};
interface CampaignDetails {
  goals: string[];
  title: string;
  templateId: string;
  listId: string;
  type: string;
  draftDate: Date;
  status: EmailCampaignFilter;
}
const CampaignManager = () => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [campaignType, setCampaignType] = useState('');
  const [draftDate, setDraftDate] = useState<Date>();
  const [goals, setGoals] = useState<string[]>([]);
  const [channel, setChannel] = useState('');
  const [goal, setGoal] = useState('');
  const [details, setDetails] = useState<CampaignDetails>({
    goals: [],
    title: '',
    templateId: '',
    listId: '',
    type: '',
    draftDate: new Date(),
    status: EmailCampaignFilter.DRAFT,
  });
  const { getLists } = useList();
  const {
    getTemplates,
    createCampaign,
    getCampaigns,
    getEmailCampaignStatusStats,
    getCampaign,
    updateCampaign,
    // saveAndSendCampaign,
    deleteCampaign,
    checkDomain,
    filterCampaigns,
    getMoreCampaigns,
  } = useEmailMarketing();
  const { debounce } = useDebounce();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'sendAt',
    direction: 'desc',
  });
  const [recentSortConfig, setRecentSortConfig] = useState<SortConfig>({
    key: 'sendAt',
    direction: 'desc',
  });
  const [activeTab, setActiveTab] = useState('list');
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [selectedList, setSelectedList] = useState<string | undefined>(
    undefined,
  );
  const [sendingOrder, setSendingOrder] = useState<string | undefined>(
    undefined,
  );
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [page, setPage] = useState(0);
  const { ref: lastElementRef, inView } = useInView({
    threshold: 1,
    delay: 100,
  });

  const campaigns = emailMarketingStore?.campaigns;

  const filteredCampaigns = emailMarketingStore?.filteredCampaigns;

  const lists = listStore.lists;

  const sortedRecentCampaigns = [...campaigns]
    .sort((a: any, b: any) => {
      if (a[recentSortConfig.key] < b[recentSortConfig.key])
        return recentSortConfig.direction === 'asc' ? -1 : 1;
      if (a[recentSortConfig.key] > b[recentSortConfig.key])
        return recentSortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    })
    .slice(0, 10);

  const cleanCampaigns =
    !searchTerm &&
    !selected &&
    !selectedList &&
    !sendingOrder &&
    !selectedTimeFrame &&
    !startDate &&
    !endDate
      ? sortedRecentCampaigns
      : filteredCampaigns;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'draft':
        return 'bg-yellow-200 text-yellow-800';
      case 'scheduled':
        return 'bg-blue-200 text-blue-800';
      case 'sent':
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  useEffect(() => {
    debounce(() => filterCampaigns({ title: searchTerm }), 500);
  }, [searchTerm]);

  const handleCheckboxChange = id => {
    setSelected(id === selected ? null : id);
  };

  const handleCheckboxTimeChange = id => {
    setSelectedTimeFrame(id === selectedTimeFrame ? null : id);
  };

  const handleSort = (key: keyof Campaign) => {
    setSortConfig(prevConfig => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const handleRecentSort = (key: keyof Campaign) => {
    setRecentSortConfig(prevConfig => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const renderCalendar = () => {
    const daysInMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0,
    ).getDate();
    const firstDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1,
    ).getDay();
    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<td key={`empty-${i}`} className='p-2 border'></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        day,
      );
      const campaignsOnDay = campaigns.filter(
        c => c.scheduledDate === currentDate.toISOString().split('T')[0],
      );

      days.push(
        <td key={day} className='p-2 border'>
          <div className='font-bold'>{day}</div>
          {campaignsOnDay.map(campaign => (
            <div
              key={campaign._id}
              className='text-xs bg-blue-100 p-1 mt-1 rounded'
            >
              {campaign.title}
            </div>
          ))}
        </td>,
      );

      if (days.length === 7 || day === daysInMonth) {
        weeks.push(<tr key={`week-${weeks.length}`}>{days}</tr>);
        days = [];
      }
    }

    return (
      <table className='w-full border-collapse'>
        <thead>
          <tr>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <th key={day} className='p-2 border'>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    );
  };

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value.trimStart(),
    }));
  }, []);

  useEffect(() => {
    emailMarketingStore.setCampaignName(details.title);
    emailMarketingStore.setDraftDate(details.draftDate);
  }, [details]);

  useEffect(() => {
    checkDomain();
  }, []);

  const handleUpdateClick = (selectedCampaign: string) => {
    getCampaign(selectedCampaign);
    setIsEditing(true);
    setIsOpenModal(true);
  };

  const openModal = () => {
    if (!emailMarketingStore.setupResponse?.domain) {
      notify('error', 'Add or verify a domain first');
      goToSetup();
    } else {
      const draftDateValue = draftDate ? new Date(draftDate) : new Date();
      setDetails(prevDetails => ({
        ...prevDetails,
        goals: [],
        title: '',
        templateId: '',
        listId: '',
        type: campaignType,
        draftDate: draftDateValue,
        status: EmailCampaignFilter.DRAFT,
      }));
      setCampaignType('');
      setDraftDate(draftDateValue);
      setGoals([]);
      setIsOpenModal(true);
    }
  };

  const handleUpdate = () => {
    updateCampaign({
      ...details,
      id: emailMarketingStore?.campaign?._id as string,
    });
    setIsOpenModal(false);
  };

  const handlSave = () => {
    createCampaign(details);
    setIsOpenModal(false);
  };

  // const handleSaveAndSend = () => {
  //   if (isEditing && emailMarketingStore?.campaign?._id) {
  //     saveAndSendCampaign({
  //       ...details,
  //       id: emailMarketingStore?.campaign?._id as string,
  //     });
  //     setIsOpenModal(false);
  //   } else {
  //     saveAndSendCampaign(details);
  //     setIsOpenModal(false);
  //   }
  // };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter' && goal.trim() !== '') {
      const goalParts = goal
        .split(',')
        .map(g => g.trim())
        .filter(g => g !== '');
      setGoals(prevGoals => [...prevGoals, ...goalParts]);
      setDetails((prevDetails: any) => ({
        ...prevDetails,
        goals: [...prevDetails.goals, ...goalParts],
      }));
      setGoal('');
    }
  };

  const handleGoalChange = (e: any) => {
    setGoal(e.target.value);
  };

  const handleRemove = (goalToRemove: string) => {
    setGoals(prevGoals => prevGoals.filter(g => g !== goalToRemove));
    setDetails((prevDetails: any) => ({
      ...prevDetails,
      goals: prevDetails.goals.filter((g: any) => g !== goalToRemove),
    }));
  };

  const handleSubmit = () => {
    if (
      !details?.title ||
      details?.goals?.length === 0 ||
      !details.listId ||
      !details.templateId
    ) {
      notify('error', 'Please fill all the details');
      return;
    }
    if (isEditing && emailMarketingStore?.campaign?._id) {
      handleUpdate();
    } else {
      handlSave();
    }
  };

  // const gotoTemplates = () => {
  //   navigate(
  //     replaceRouteParam(
  //       `${routeNames.dashboard.emailMarketing}/templates`,
  //       'workspaceId',
  //       AuthStore.user_workspace_info.active_workspace.workspace._id,
  //     ),
  //   );
  // };
  const next = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const goToSetup = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.integration}`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const handleDateChange: SelectSingleEventHandler = (date: any) => {
    const selectedDate = new Date(date);
    const formattedDate = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1,
    ).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    setDraftDate(formattedDate);
    setDetails(prevDetails => ({
      ...prevDetails,
      draftDate: formattedDate,
    }));
  };

  const handleCampaignTypeChange = (type: string) => {
    setCampaignType(type);
    setChannel(type);
    setDetails(prevDetails => ({
      ...prevDetails,
      type,
    }));
  };

  useEffect(() => {
    getLists();
    getTemplates();
  }, []);

  useEffect(() => {
    if (isEditing && emailMarketingStore?.campaign) {
      const campaignData = emailMarketingStore.campaign;
      setDetails({
        goals: campaignData?.goals || [],
        title: campaignData?.title || '',
        templateId: campaignData?.template || '',
        listId: campaignData?.listId || '',
        status: campaignData?.status || '',
        type: campaignData?.type || '',
        draftDate: campaignData?.draftDate || '',
      });
      setCampaignType(campaignData?.type || '');
      setDraftDate(
        campaignData?.draftDate
          ? new Date(campaignData.draftDate)
          : campaignData.draftDate,
      );

      setGoals(campaignData?.goals || []);
    }
  }, [emailMarketingStore?.campaign, isEditing]);

  useEffect(() => {
    if (
      inView &&
      emailMarketingStore.campaignMetaData?.hasMore &&
      !emailMarketingStore.campaignPageListLoader
    ) {
      getMoreCampaigns({
        filter: EmailCampaignFilter.ALL,
        page: page,
      });
      getEmailCampaignStatusStats();
      setPage(prevPage => prevPage + 1);
    }
  }, [inView, EmailCampaignFilter.ALL]);

  useEffect(() => {
    getCampaigns({
      filter: EmailCampaignFilter.ALL,
    });
    getEmailCampaignStatusStats();
    setPage(prevPage => prevPage + 1);
  }, [EmailCampaignFilter.ALL]);

  if (emailMarketingStore.loading) {
    return (
      <div className='h-[80vh] flex justify-center bg-white rounded-[16px] m-6'>
        <Spinner color='blue' size={50} />
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col md:flex-row bg-gray-100 h-full'>
        {/* Mobile Header */}
        <header className='md:hidden bg-[#2563EB] text-white p-4 flex justify-between items-center'>
          <Button
            variant='ghost'
            size='icon'
            // onClick={() => setSidebarOpen(true)}
          >
            <Menu className='h-6 w-6' />
          </Button>
          <h1 className='text-xl font-bold'>Email Campaigns</h1>
          <Button variant='ghost' size='icon'>
            <Settings className='h-6 w-6' />
          </Button>
        </header>

        {/* Main content */}
        <main className='flex-1 p-4 md:p-8 overflow-auto'>
          <div className='bg-white p-4 md:p-6 rounded-lg shadow-sm'>
            <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-6'>
              <h1 className='text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-0'>
                All Campaigns
              </h1>
              <div className='flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4'>
                <Button variant='outline' className='w-full md:w-auto'>
                  Options
                </Button>
                <Button
                  onClick={openModal}
                  className='w-full md:w-auto bg-black hover:bg-gray-800 text-white'
                >
                  Create campaign
                </Button>
              </div>
            </div>

            {/* BFCM Banner */}
            {/* <Card className='mb-6 bg-gradient-to-r from-red-500 to-pink-500 text-white relative'>
              <button className='absolute top-2 right-2 text-white hover:text-gray-200'>
                <X className='h-4 w-4' />
              </button>
              <CardHeader>
                <CardTitle>BFCM 2024</CardTitle>
              </CardHeader>
              <CardContent>
                <p>
                  Find how-tos, to-dos, live trainings, proven strategies, and
                  more on our BFCM resource center.
                </p>
              </CardContent>
              <CardFooter>
                <Button
                  variant='outline'
                  className='w-full md:w-auto text-black'
                  onClick={gotoTemplates}
                >
                  Explore templates for BFCM
                </Button>
              </CardFooter>
            </Card> */}

            <Tabs
              value={activeTab}
              onValueChange={setActiveTab}
              className='mb-6'
            >
              <TabsList className='grid w-full grid-cols-2'>
                <TabsTrigger value='list' className='text-md'>
                  List view
                </TabsTrigger>
                <TabsTrigger value='calendar' className='text-md'>
                  Calendar
                </TabsTrigger>
              </TabsList>
            </Tabs>

            {activeTab == 'list' && (
              <div className='flex flex-col space-y-4 mb-6'>
                <div className='relative flex-grow'>
                  <Search className='absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400' />
                  <Input
                    type='text'
                    placeholder='Search campaigns...'
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className='pl-10 pr-4 py-2 w-full focus-visible:outline-none focus-visible:ring-0
                   focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                  />
                </div>
                <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                  <Select>
                    <SelectTrigger className='w-full md:w-[200px] focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                      <Settings className='w-4 h-4 mr-2' />
                      <SelectValue placeholder='Active on Site' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='active'>Active on Site</SelectItem>
                      <SelectItem value='inactive'>Inactive on Site</SelectItem>
                    </SelectContent>
                  </Select>
                  <Select>
                    <SelectTrigger className='w-full md:w-[200px] focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                      <SelectValue placeholder='Last 30 days' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='30'>Last 30 days</SelectItem>
                      <SelectItem value='60'>Last 60 days</SelectItem>
                      <SelectItem value='90'>Last 90 days</SelectItem>
                    </SelectContent>
                  </Select>
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button
                        variant='outline'
                        className='w-full md:w-[120px] bg-gray-100 hover:bg-gray-200'
                      >
                        <Filter className='w-4 h-4 mr-2' />
                        Filters
                      </Button>
                    </SheetTrigger>
                    <SheetContent className='w-[300px] sm:w-[400px] max-h-screen flex flex-col'>
                      <SheetHeader>
                        <SheetTitle>Filters</SheetTitle>
                      </SheetHeader>
                      <ScrollArea className='flex-grow py-4'>
                        <div className='pr-4'>
                          <h3 className='mt-4 mb-2 font-semibold'>Status</h3>
                          <div className='space-y-2'>
                            {['Sent', 'Scheduled', 'Draft'].map(id => (
                              <div key={id} className='flex items-center'>
                                <Checkbox
                                  id={id}
                                  checked={selected === id}
                                  onCheckedChange={() =>
                                    handleCheckboxChange(id)
                                  }
                                />
                                <label htmlFor={id} className='ml-2 capitalize'>
                                  {id.replace('_', ' ')}
                                </label>
                              </div>
                            ))}
                          </div>
                          <h3 className='mt-4 mb-2 font-semibold'>
                            Sending order
                          </h3>
                          <Select
                            value={sendingOrder}
                            onValueChange={value => setSendingOrder(value)}
                          >
                            <SelectTrigger
                              className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                            >
                              <SelectValue placeholder='Select order' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='Ascending'>
                                Ascending
                              </SelectItem>
                              <SelectItem value='Descending'>
                                Descending
                              </SelectItem>
                            </SelectContent>
                          </Select>
                          {/* <h3 className='mt-4 mb-2 font-semibold'>Tags</h3>
                          <Select>
                            <SelectTrigger
                              className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                            >
                              <SelectValue placeholder='Select tags' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='tag1'>Tag 1</SelectItem>
                              <SelectItem value='tag2'>Tag 2</SelectItem>
                            </SelectContent>
                          </Select> */}
                          <h3 className='mt-4 mb-2 font-semibold'>List</h3>
                          <Select
                            value={selectedList}
                            onValueChange={value => {
                              setSelectedList(value);
                            }}
                          >
                            <SelectTrigger
                              className='w-full focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
                            >
                              <SelectValue placeholder='Select one or more options' />
                            </SelectTrigger>
                            <SelectContent className='w-full h-full'>
                              {lists.map(list => (
                                <SelectItem key={list._id} value={list._id}>
                                  {list.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <h3 className='mt-4 mb-2 font-semibold'>Date Sent</h3>
                          <div className='space-y-2'>
                            {[
                              'Last_7_days',
                              'Last_30_days',
                              'Last_60_days',
                              'Custom',
                            ].map(id => (
                              <div key={id} className='flex items-center'>
                                <Checkbox
                                  id={id}
                                  checked={selectedTimeFrame === id}
                                  onCheckedChange={() =>
                                    handleCheckboxTimeChange(id)
                                  }
                                />
                                <label htmlFor={id} className='ml-2 capitalize'>
                                  {id.replace(/_/g, ' ')}
                                </label>
                              </div>
                            ))}
                          </div>
                          {selectedTimeFrame == 'Custom' && (
                            <div className='mt-2 space-y-2'>
                              <Input
                                type='date'
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                                placeholder='Start date'
                              />
                              <Input
                                type='date'
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                placeholder='End date'
                              />
                            </div>
                          )}
                        </div>
                      </ScrollArea>
                      <SheetFooter>
                        <SheetClose>
                          <Button
                            onClick={() =>
                              filterCampaigns({
                                title: '',
                                status: selected,
                                listId: selectedList,
                                dateRange: selectedTimeFrame,
                                sendingOrder: sendingOrder,
                                customStart: startDate,
                                customEnd: endDate,
                              })
                            }
                            disabled={
                              !selected &&
                              !selectedList &&
                              !sendingOrder &&
                              !selectedTimeFrame &&
                              !startDate &&
                              !endDate
                            }
                            className='w-full bg-black text-white'
                          >
                            Apply Filters
                          </Button>
                        </SheetClose>
                      </SheetFooter>
                    </SheetContent>
                  </Sheet>
                </div>
              </div>
            )}
            <Tabs>
              <TabsContent value='tabble-list'>
                <div className='mb-4 flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0'>
                  <div className='flex items-center space-x-4'>
                    <Button onClick={() => setSelectedDate(new Date())}>
                      Today
                    </Button>
                    <Button
                      variant='outline'
                      onClick={() =>
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() - 1,
                            1,
                          ),
                        )
                      }
                    >
                      <ChevronLeft className='h-4 w-4' />
                    </Button>
                    <Button
                      variant='outline'
                      onClick={() =>
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() + 1,
                            1,
                          ),
                        )
                      }
                    >
                      <ChevronRight className='h-4 w-4' />
                    </Button>
                    <span className='text-lg font-semibold'>
                      {selectedDate.toLocaleString('default', {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </span>
                  </div>
                  <Select defaultValue='month'>
                    <SelectTrigger className='w-[180px]'>
                      <SelectValue placeholder='Select view' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='day'>Day</SelectItem>
                      <SelectItem value='week'>Week</SelectItem>
                      <SelectItem value='month'>Month</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className='overflow-x-auto'>{renderCalendar()}</div>
              </TabsContent>
            </Tabs>
          </div>
          {/* Recent Campaigns Table */}
          {activeTab == 'list' ? (
            <div
              className={`my-10 bg-white ${
                emailMarketingStore.campaignListLoader ? 'h-[476px]' : ''
              } p-4 md:p-6 rounded-lg shadow-sm`}
            >
              <h2 className='text-xl md:text-2xl font-bold mb-6'>
                {!searchTerm ? 'Recent Campaigns' : 'All Campaigns'}
              </h2>
              <div className='overflow-x-auto'>
                {!cleanCampaigns?.length ? (
                  <div className='flex justify-center items-center'>
                    <EmptyList
                      listName={''}
                      title='There are no campaigns'
                      subTitle='Add campaigns to see them here'
                      height='25vh'
                    />
                  </div>
                ) : (
                  <div>
                    {emailMarketingStore.campaignListLoader ? (
                      <div className='h-[350px] w-full flex items-center justify-center'>
                        <Spinner color='blue' size={40} />
                      </div>
                    ) : (
                      <div className='overflow-auto max-h-[300px]'>
                        <Table>
                          <TableHeader className=' text-md'>
                            <TableRow>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('title')
                                    : handleSort('title');
                                }}
                                className='cursor-pointer'
                              >
                                Name{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'title' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'title' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('type')
                                    : handleSort('type');
                                }}
                                className='cursor-pointer hidden md:table-cell'
                              >
                                Type{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'type' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'type' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('status')
                                    : handleSort('status');
                                }}
                                className='cursor-pointer'
                              >
                                Status{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'status' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'status' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('sendAt')
                                    : handleSort('sendAt');
                                }}
                                className='cursor-pointer hidden md:table-cell'
                              >
                                Sent Date{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'sendAt' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'sendAt' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('sentCount')
                                    : handleSort('sentCount');
                                }}
                                className='cursor-pointer text-right hidden md:table-cell'
                              >
                                Delivered{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'sentCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'sentCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('openCount')
                                    : handleSort('openCount');
                                }}
                                className='cursor-pointer text-right hidden md:table-cell'
                              >
                                Opens{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'openCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'openCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead
                                onClick={() => {
                                  !searchTerm
                                    ? handleRecentSort('clickCount')
                                    : handleSort('clickCount');
                                }}
                                className='cursor-pointer text-right hidden md:table-cell'
                              >
                                Clicks{' '}
                                {!searchTerm
                                  ? recentSortConfig.key === 'clickCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )
                                  : sortConfig.key === 'clickCount' && (
                                      <ArrowUpDown className='ml-2 h-4 w-4 inline' />
                                    )}
                              </TableHead>
                              <TableHead>Actions</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody className='overflow-y-auto max-h-[300px]'>
                            {cleanCampaigns.map((campaign, index) => {
                              // If it's the last item in the list, add the ref
                              const isLastItem =
                                index === cleanCampaigns.length - 1;
                              return (
                                <TableRow
                                  className='overflow-y-auto'
                                  key={index}
                                  ref={isLastItem ? lastElementRef : null}
                                >
                                  <TableCell className='font-medium text-md !border-0'>
                                    {campaign.title || '-'}
                                  </TableCell>
                                  <TableCell className='hidden md:table-cell text-md !border-0'>
                                    {campaign.type || '-'}
                                  </TableCell>
                                  <TableCell className='text-md !border-0'>
                                    <Badge
                                      className={getStatusColor(
                                        campaign.status,
                                      )}
                                    >
                                      {campaign.status.charAt(0).toUpperCase() +
                                        campaign.status.slice(1)}
                                    </Badge>
                                  </TableCell>
                                  <TableCell className='hidden md:table-cell text-md !border-0'>
                                    {format(
                                      new Date(campaign.sendAt),
                                      'PPpp',
                                    ) || '-'}
                                  </TableCell>
                                  <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                                    {campaign.sentCount || '0'}
                                  </TableCell>
                                  <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                                    {campaign.openCount || '0'}
                                  </TableCell>
                                  <TableCell className='text-right hidden md:table-cell text-md !border-0'>
                                    {campaign.clickCount || '0'}
                                  </TableCell>
                                  <TableCell className='text-md !border-0'>
                                    <DropdownMenu>
                                      <DropdownMenuTrigger asChild>
                                        <Button
                                          variant='outline'
                                          size='icon'
                                          className='h-8 w-8'
                                        >
                                          <MoreVertical className='h-4 w-4' />
                                        </Button>
                                      </DropdownMenuTrigger>
                                      <DropdownMenuContent align='end'>
                                        <DropdownMenuItem
                                          onClick={() =>
                                            handleUpdateClick(campaign._id)
                                          }
                                          className='text-md'
                                        >
                                          <Edit className='mr-2 h-4 w-4' />
                                          Edit
                                        </DropdownMenuItem>
                                        <DropdownMenuItem className='text-md'>
                                          <Copy className='mr-2 h-4 w-4' />
                                          Clone
                                        </DropdownMenuItem>
                                        <DropdownMenuItem className='text-md'>
                                          <BarChart className='mr-2 h-4 w-4' />
                                          Analytics
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          onClick={e => {
                                            e.stopPropagation();
                                            emailMarketingStore.setCampaign(
                                              campaign,
                                            );
                                            setIsOpenConfirmationModal(true);
                                          }}
                                          className='text-md'
                                        >
                                          <Trash className='mr-2 h-4 w-4' />
                                          Delete
                                        </DropdownMenuItem>
                                      </DropdownMenuContent>
                                    </DropdownMenu>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>

                          {/* Infinite Scroll Loader */}
                          {emailMarketingStore.campaignPageListLoader && (
                            <Spinner size={20} />
                          )}
                        </Table>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <CampaignCalendar />
          )}
        </main>
      </div>
      <Dialog open={isOpenModal} onOpenChange={setIsOpenModal}>
        <DialogContent className='bg-white border-none p-8 w-full max-w-xl max-h-[90vh] overflow-y-auto [&>button]:hidden'>
          <DialogHeader className='flex flex-row justify-between items-center'>
            <DialogTitle className='text-2xl font-semibold text-secondary'>
              Create
            </DialogTitle>
            <DialogClose asChild>
              <Button variant='ghost' size='icon'>
                <X className='h-6 w-6' />
              </Button>
            </DialogClose>
          </DialogHeader>
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4'>
            <div>
              <Label htmlFor='campaignName'>Campaign Name</Label>
              <Input
                id='campaignName'
                name='title'
                placeholder="Enter campaign name (e.g., 'Holiday Promo 2024')"
                value={details.title}
                onChange={handleChange}
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                required
              />
            </div>
            <div>
              <Label htmlFor='draftDate'>Draft Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !draftDate && 'text-muted-foreground',
                    )}
                  >
                    <Calendar className='mr-2 h-4 w-4' />
                    {draftDate ? format(draftDate, 'PPP') : 'Select a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <CalendarComponent
                    mode='single'
                    selected={draftDate}
                    onSelect={date => handleDateChange(date)}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className='space-y-2'>
              <Label>Type</Label>
              <RadioGroup
                value={campaignType}
                onValueChange={value => handleCampaignTypeChange(value)}
                className='space-y-2'
                name='campaignType'
              >
                {['EMAIL', 'SMS', 'PUSH'].map(type => (
                  <div
                    key={type}
                    className={`flex items-center space-x-2 rounded-md border p-4 transition-colors ${
                      campaignType === type
                        ? '!bg-secondary text-white'
                        : 'hover:bg-secondary/10'
                    } ${
                      type == 'SMS' || type == 'PUSH'
                        ? 'opacity-35 hover:bg-none'
                        : ''
                    }`}
                  >
                    <RadioGroupItem
                      disabled={type == 'SMS' || type == 'PUSH'}
                      value={type}
                      id={type}
                    />
                    <Label
                      htmlFor={type}
                      className={`flex items-center cursor-pointer`}
                    >
                      {type === 'EMAIL' && <Mail className='mr-2 h-4 w-4' />}
                      {type === 'SMS' && (
                        <MessageSquare className='mr-2 h-4 w-4' />
                      )}
                      {type === 'PUSH' && <Bell className='mr-2 h-4 w-4' />}
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
              <p className='text-md text-muted-foreground mt-1'>
                Push messages will go out from your Sticky Card.
              </p>
            </div>
            <div>
              <Label htmlFor='goals'>Tags</Label>
              <Input
                id='goals'
                name='goals'
                value={goal}
                onChange={handleGoalChange}
                onKeyUp={handleKeyUp}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                placeholder='Select or add tags (e.g., Holiday, Promotion)'
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
              />
              <div className='flex flex-wrap gap-2 mt-2'>
                {goals.map((tag, index) => (
                  <span
                    key={index}
                    className='bg-primary/10 text-black px-2 py-1 rounded text-md'
                  >
                    {tag}
                    <button
                      type='button'
                      className='ml-2 text-primary'
                      onClick={() => handleRemove(tag)}
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
              <p className='text-md text-muted-foreground mt-1'>
                Add tags to organize and track campaigns.
              </p>
            </div>
            <div className='flex justify-end space-x-2 mt-4'>
              <Button type='button' variant='outline' onClick={handleSubmit}>
                Save draft
              </Button>
              <Button
                type='button'
                className='bg-secondary text-white hover:bg-secondary/80'
                onClick={next}
                disabled={!details.title || !campaignType}
              >
                Continue
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmationModal
        title='Delete Campaign'
        content='Are you sure you want to delete this campaign?'
        show={isOpenConfirmationModal}
        setShow={setIsOpenConfirmationModal}
        confirmText='Done'
        onConfirm={() => {
          deleteCampaign(emailMarketingStore?.campaign?._id as string);
          setIsOpenConfirmationModal(false);
        }}
      />
    </>
  );
};
export default observer(CampaignManager);
