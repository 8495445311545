import { Separator } from '@/components/ui/separator';
import CurrentPlan from './current-plan';
import CreditSummary from './credit-summary';
import EmailCredits from './email-credits';
import SmsCredits from './sms-credits';

export default function CreditManagement() {
  return (
    <div className='bg-white pb-12'>
      <div className='max-w-4xl mx-auto py-6 px-4'>
        <h1 className='text-3xl font-bold tracking-tight'>Update your plan</h1>
        <p className='text-muted-foreground mt-2'>
          Manage your subscription and credits
        </p>
        <div className='mt-8 space-y-6'>
          <CurrentPlan />
          <Separator />
          <CreditSummary />
          <Separator />
          <EmailCredits />
          <Separator />
          <SmsCredits />
        </div>
      </div>
    </div>
  );
}
