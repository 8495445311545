'use client';

import { useState } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';

const PLANS = [
  { name: 'Essentials', price: 299 },
  { name: 'Premium', price: 499 },
  { name: 'Brand', price: 799 },
  { name: 'Enterprise', price: 'Contact Us' },
  { name: 'Unlimited', price: 0 },
];

export default function CurrentPlan() {
  const [currentPlan, setCurrentPlan] = useState(PLANS[0]);
  const [selectedPlan, setSelectedPlan] = useState(PLANS[0].name);

  const handlePlanChange = (planName: string) => {
    setSelectedPlan(planName);
  };

  const handleUpdatePlan = () => {
    const newPlan = PLANS.find(plan => plan.name === selectedPlan);
    if (newPlan) {
      setCurrentPlan(newPlan);
    }
  };

  return (
    <Card>
      <CardHeader className='flex justify-between items-start'>
        <div>
          <CardTitle>Current Plan</CardTitle>
          <CardDescription className='text-md'>
            Manage your subscription plan
          </CardDescription>
        </div>
        <a href='/billing' className='text-md text-[#033EB5] hover:underline'>
          Go to Billing
        </a>
      </CardHeader>
      <CardContent>
        <div className='space-y-4'>
          <div>
            <p className='text-md font-medium'>Your current plan:</p>
            <h3 className='text-2xl font-bold'>{currentPlan.name}</h3>
            <p className='text-md text-muted-foreground'>
              {currentPlan.price === 'Contact Us'
                ? 'Custom pricing'
                : currentPlan.price === 0
                ? 'Free'
                : `$${currentPlan.price}/month`}
            </p>
          </div>
          <div className='space-y-2'>
            <p className='text-md font-medium'>Change your plan:</p>
            <Select value={selectedPlan} onValueChange={handlePlanChange}>
              <SelectTrigger className='w-full focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                <SelectValue placeholder='Select a plan' />
              </SelectTrigger>
              <SelectContent>
                {PLANS.map(plan => (
                  <SelectItem key={plan.name} value={plan.name}>
                    <div className='flex justify-between items-center gap-2'>
                      <span>{plan.name}</span>
                      <span className='text-muted-foreground'>
                        {plan.price === 'Contact Us'
                          ? plan.price
                          : plan.price === 0
                          ? 'Free'
                          : `$${plan.price}/mo`}
                      </span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button
            onClick={handleUpdatePlan}
            className='w-full text-white bg-secondary hover:bg-secondary/90'
            disabled={currentPlan.name === selectedPlan}
          >
            Update Plan
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
