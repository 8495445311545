import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

export default function CreditSummary() {
  return (
    <div className='grid gap-4 md:grid-cols-2'>
      <Card>
        <CardHeader className='pb-2'>
          <CardTitle className='text-lg font-medium'>Email Credits</CardTitle>
          <CardDescription className='text-md'>
            Available credits for sending emails
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className='text-2xl font-bold'>2,500</div>
          <p className='text-md text-muted-foreground'>500 used this month</p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className='pb-2'>
          <CardTitle className='text-lg font-medium'>SMS Credits</CardTitle>
          <CardDescription className='text-md'>
            Available credits for sending SMS
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className='text-2xl font-bold'>150</div>
          <p className='text-md text-muted-foreground'>50 used this month</p>
        </CardContent>
      </Card>
    </div>
  );
}
