const PREFIX = {
  dashboard: '/d/:workspaceId',
  integration: `integration`,
  auth: '/auth',
  workspaces: '/workspaces',
  teams: '/teams',
  verification: 'verification',
  pwa: '/pwa',
  inbox: 'inbox',
} as const;

const routeNames = {
  landing: '/',
  privacyPolicy: '/privacy-policy',
  termsOfService: '/terms-of-service',
  demo: '/demo',
  dashboard: {
    home: PREFIX.dashboard,
    chat: `${PREFIX.dashboard}/${PREFIX.inbox}/chat`,
    email: `${PREFIX.dashboard}/${PREFIX.inbox}/email`,
    sms: `${PREFIX.dashboard}/${PREFIX.inbox}/sms`,
    messenger: `${PREFIX.dashboard}/${PREFIX.inbox}/messenger`,
    whatsapp: `${PREFIX.dashboard}/${PREFIX.inbox}/whatsapp`,
    contacts: `${PREFIX.dashboard}/contacts`,
    userProfile: `${PREFIX.dashboard}/contacts/contact-profile`,
    allContacts: `${PREFIX.dashboard}/all-contacts`,
    loyalty: `${PREFIX.dashboard}/loyalty`,
    configurations: `${PREFIX.dashboard}/configurations`,
    agentProfile: `${PREFIX.dashboard}/profile`,
    marketing: `${PREFIX.dashboard}/marketing`,
    settings: `${PREFIX.dashboard}/settings`,
    invites: `${PREFIX.dashboard}/settings/teammates/invites`,
    permissions: `${PREFIX.dashboard}/settings/teammates/invites/permissions`,
    integration: `${PREFIX.dashboard}/integration`,
    addContact: `${PREFIX.dashboard}/add-contact`,
    customerProfile: `${PREFIX.dashboard}/contact`,
    calendar: `${PREFIX.dashboard}/calendar`,
    emailTemplates: `${PREFIX.dashboard}/create-email-template`,
    campaignReview: `${PREFIX.dashboard}/campaign-review`,
    pageTemplates: `${PREFIX.dashboard}/create-page-template`,
    popUpTemplates: `${PREFIX.dashboard}/create-popup-templates`,
    teams: `${PREFIX.dashboard}/teams`,
    emailMarketing: `${PREFIX.dashboard}/email-marketing`,
    billing: `${PREFIX.dashboard}/billing`,
  },
  appDownload: 'download',
  pwa: {
    loyalty: `${PREFIX.pwa}/loyalty`,
  },
  authentication: {
    login: `${PREFIX.auth}/login`,
    signUp: `/sign-up`,
    forgotPassword: `/forgot-password`,
    resetPassword: `/reset-password`,
  },
  workspaces: {
    root: PREFIX.workspaces,
    add: `${PREFIX.workspaces}/add`,
    no_workspaces: `${PREFIX.workspaces}/no_workspaces`,
  },
  teams: {
    join: `${PREFIX.teams}/join`,
    sign_up: `${PREFIX.teams}/sign_up`,
  },
  emailVerification: {
    verificationPage: `${PREFIX.verification}/email-verification`,
    verifyEmail: `${PREFIX.verification}/email-verification/:code`,
  },
} as const;

export default routeNames;
