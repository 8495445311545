import Button from '@/components/atoms/Button';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

type IntegrationListItemProps = {
  icon: string;
  title: string;
  phrase: string;
  isLast: boolean;
  isConnected: boolean;
  isDisabled: boolean;
  handleClick: () => void;
  loading: boolean;
};

const IntegrationListItem: FC<IntegrationListItemProps> = ({
  icon,
  title,
  phrase,
  isConnected,
  isDisabled,
  handleClick,
  loading,
}) => {
  return (
    <div className='space-y-4'>
      <div
        className={`flex flex-row items-center justify-between px-4 h-[60px]`}
      >
        <div className='flex flex-row items-center space-x-[10px]'>
          <div className='ml-[5px] w-[40px] h-[40px]'>
            <img src={icon} alt='logo' />
          </div>
          <div className='flex flex-col'>
            <h3
              className={`group-hover:text-primary-medium text-[18px] font-[600] leading-[20px] text-[#222] text-opacity-90`}
            >
              {title}
            </h3>
            <p className='text-[16px] leading-[20px] text-gray-500'>{phrase}</p>
          </div>
        </div>
        <div className='flex gap-3'>
          <Button
            disabled={isDisabled && !isConnected}
            text={!isConnected ? 'Connect' : 'Disconnect'}
            onClick={handleClick}
            loading={loading}
            className={`${
              isConnected
                ? 'bg-red-500 hover:bg-red-600'
                : isDisabled
                ? 'bg-gray-500'
                : 'bg-primary-medium hover:bg-primary-dark h-[40px]'
            }`}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(IntegrationListItem);
