import { EmailParameters } from '@/pages/EmailMarketing/types';
import { User } from '@/types/user.types';
import { makeAutoObservable } from 'mobx';

type DomainVerificationStatus =
  | 'awaiting-submission'
  | 'pending'
  | 'verified'
  | 'failed'
  | 'success';

export enum EmailCampaignFilter {
  ALL = 'All',
  DRAFT = 'Draft',
  SENT = 'Sent',
  ARCHIVED = 'Archived',
  SCHEDULED = 'Scheduled',
}

type DomainRecordType = 'TXT' | 'MX' | 'CNAME';

type DomainRecord = {
  type: DomainRecordType;
  key: string;
  value: string;
  priority: string;
};

type SetupResponse = {
  domain: string;
  verified: boolean;
  status: DomainVerificationStatus;
  senderName: string;
  domainRecords: DomainRecord[];
};
export type Template = {
  _id: string;
  html: any;
  name: string;
  thumbnail: string;
  json: string;
  createdAt: string;
  updatedAt: string;
  createdBy: any;
  status: string;
  type: string;
  tags: string[];
};
type Campaign = {
  clickCount: number;
  createdAt: string;
  goals: any;
  campaigns: any;
  listId: string;
  openCount: number;
  sendAt: string;
  scheduledDate: string;
  type: string;
  draftDate: Date;
  sentCount: number;
  status: EmailCampaignFilter;
  template: string;
  templateId: string;
  title: string;
  updatedAt: string;
  workspace: string;
  _id: string;
};

type EmailMarketingStats = {
  sent: number;
  opened: number;
  clicked: number;
};

type CampaignStatusStats = {
  label: EmailCampaignFilter;
  count: number;
  isActive?: boolean;
}[];

type TemplateOnDemand = {
  html: any;
  name: string;
  type: string;
  json: any;
  status: string;
};

class EmailMarketingStore {
  constructor() {
    makeAutoObservable(this);
  }

  isReady = false;
  loading = false;
  campaignListLoader = false;
  campaignPageListLoader = false;
  templateLoader = false;
  moreTemplateLoader = false;
  showSpinner = false;
  sendingTestEmail = false;
  verificationStatus: DomainVerificationStatus = 'awaiting-submission';
  setupResponse: SetupResponse | null = null;
  templates: Template[] | null = null;
  filteredTemplates: Template[] | null = null;
  template: Template | null = null;
  campaigns: Campaign[] = [];
  filteredCampaigns: Campaign[] = [];
  campaign: Campaign | null = null;
  templateOnDemand: TemplateOnDemand | null = null;
  campaignStatusStats: CampaignStatusStats = [];
  emailParams: EmailParameters = {
    preview_text: '',
    sender_name: this.setupResponse?.senderName
      ? this.setupResponse.senderName
      : '',
    subject_line: '',
  };
  sender_email = '';
  campaignName = '';
  templateId = '';
  type = '';
  draftDate = '';
  list: User[] = [];
  description = '';
  isDomainVerified = false;
  campaignMetaData: {
    count: number;
    page: number;
    hasMore: boolean;
  } | null = null;
  templateMetaData: {
    count: number;
    page: number;
    hasMore: boolean;
  } | null = null;
  emailMarketingStats: EmailMarketingStats = {
    sent: 0,
    opened: 0,
    clicked: 0,
  };
  setReady() {
    this.isReady = true;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setCampaignListLoader(loading: boolean) {
    this.campaignListLoader = loading;
  }

  setCampaignPageListLoader(loading: boolean) {
    this.campaignPageListLoader = loading;
  }

  setTemplateLoader(loading: boolean) {
    this.templateLoader = loading;
  }

  setMoreTemplateLoader(loading: boolean) {
    this.moreTemplateLoader = loading;
  }

  setShowSpinner(showSpinner: boolean) {
    this.showSpinner = showSpinner;
  }

  setEmailCampaignStatusStats(stats: CampaignStatusStats) {
    this.campaignStatusStats = stats;
  }

  setVerificationStatus(status: DomainVerificationStatus) {
    this.verificationStatus = status;
  }

  setSetupResponse(response: SetupResponse | null) {
    this.setupResponse = response;
  }
  setTemplates(templates: Template[] | any) {
    this.templates = templates;
  }
  setFilteredTemplates(templates: Template[] | null) {
    this.filteredTemplates = templates;
  }
  setTemplateOnDemand(template: TemplateOnDemand) {
    this.templateOnDemand = template;
  }
  setTemplate(template: Template | null) {
    this.template = template;
  }
  setCampaigns(campaigns: Campaign[] | any) {
    this.campaigns = campaigns;
  }
  setCampaignMetaData(payload: {
    count: number;
    page: number;
    hasMore: boolean;
  }) {
    this.campaignMetaData = payload;
  }
  setTemplateMetaData(payload: {
    count: number;
    page: number;
    hasMore: boolean;
  }) {
    this.templateMetaData = payload;
  }
  setFilteredCampaigns(campaigns: Campaign[]) {
    this.filteredCampaigns = campaigns;
  }
  setCampaign(campaign: Campaign | null) {
    this.campaign = campaign;
  }

  setEmailMarketingStats(stats: EmailMarketingStats) {
    this.emailMarketingStats = stats;
  }
  setSendingTestEmail(state: boolean) {
    this.sendingTestEmail = state;
  }
  setEmailParams(value: EmailParameters) {
    this.emailParams = value;
  }
  set_sender_email(value: string) {
    this.sender_email = value;
  }
  setCampaignName(name: string) {
    this.campaignName = name;
  }
  setDraftDate(date: string) {
    this.draftDate = date;
  }
  setIsDomainVerified(value: boolean) {
    this.isDomainVerified = value;
  }
}

export const emailMarketingStore = new EmailMarketingStore();
