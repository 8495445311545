import { useHttp } from '@/hooks/useHttp';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { process_retry_event } from '@/utils/index';

import { SOCKET_EVENT_NAMES } from '@/constants/socket.events';
import { notify } from '@/helpers/index';
import { SocketResponse } from '@/types/socketResponse.type';
import { GoogleProfile, User } from '@/types/user.types';
import { replaceRouteParam } from '@/utils/routes';
import { TokenResponse } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export type SocialAuthProfile = {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  picture: string;
};

export type RegisterPayload = {
  user_name: string;
  email: string;
  company_name: string;
  company_size: string;
  timezone: string;
  password: string;
};

export type LoginPayload = {
  email: string;
  password: string;
};

export type ForgotPasswordPayload = {
  email: string;
};

export type ResetPasswordPayload = {
  password: string;
  token: string;
};

export enum AUTH_PROVIDER {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  EMAIL = 'EMAIL',
}

export const useAuth = () => {
  const { get, post, destroy } = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : replaceRouteParam(
        `${routeNames.dashboard.home}`,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      );

  const login = async (data: LoginPayload) => {
    AuthStore.requestLogin();

    try {
      const response = await post('/api/auth/login', data, {
        withCredentials: true,
      });
      AuthStore.setUserAndSocket(response);
    } catch (e) {
      AuthStore.setLoginError(e);
      if (e instanceof AxiosError) {
        notify('error', e?.response?.data?.message);
      }
    }
  };

  const refreshSocketToken = (payload: any) => {
    if (
      AuthStore.socket === null ||
      AuthStore.socket._readyState === 'closed'
    ) {
      AuthStore.requestRefreshTokenSuccess(payload);
    } else {
      AuthStore.requestRefreshTokenSuccess(payload);
      AuthStore.socket.emit('refresh_token', {
        event_name: 'refresh_token',
        data: payload.data.user,
      });
    }
  };
  const refreshToken = async (silent = false) => {
    AuthStore.requestRefreshToken(!silent);

    try {
      const response = await get('/api/auth/refresh_token/', {
        withCredentials: true,
      });

      refreshSocketToken(response);
      AuthStore.setIsLoading(false);
      return response;
    } catch (e) {
      AuthStore.setIsLoading(false);
    }
  };

  const retryEvents = (payload: any) => {
    payload.data.map((item: any) => {
      const parsedItem = JSON.parse(item);

      if (parsedItem.event_name !== null) {
        AuthStore.socket?.emit(
          parsedItem.event_name,
          parsedItem,
          (response: any) => {
            process_retry_event(
              parsedItem.event_name,
              response,
              parsedItem.data,
            );
          },
        );
      }
    });
  };

  const update_user_info_async = (update: any) => {
    AuthStore.set_updating_user_info(true);
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.UPDATE_USER_INFO,
      { event_name: SOCKET_EVENT_NAMES.UPDATE_USER_INFO, data: update },
      (response: any) => {
        AuthStore.set_updating_user_info(false);
        if (response.data.error) {
          notify('error', response?.data?.error);
        } else {
          AuthStore.update_user_info(response.data);
          notify('success', 'User info updated successfully');
        }
      },
    );
  };
  const register = async (data: RegisterPayload) => {
    AuthStore.setLoading(true);
    try {
      const response = await post<User>('/api/auth/signup', data);

      AuthStore.setUserAndSocket(response);
      navigate(redirectTo, { replace: true });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify('error', error?.response?.data?.message);
      } else {
        notify('error', 'Something went wrong');
      }
      AuthStore.setLoginError(error);
    } finally {
      AuthStore.setLoading(false);
    }
  };

  const forgotPassword = async (data: ForgotPasswordPayload) => {
    AuthStore.setLoading(true);
    try {
      await post<User>('/api/auth/forgot-password', data);
      AuthStore.setPasswordResetSuccess(true);
      notify('success', 'Password reset link sent to your email');
    } catch (error: any) {
      AuthStore.setPasswordResetSuccess(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        notify('error', error.response.data.message);
      } else {
        notify('error', 'An unexpected error occurred');
      }
    } finally {
      AuthStore.setLoading(false);
    }
  };

  const resetPassword = (data: ResetPasswordPayload) => {
    AuthStore.setLoading(true);
    post<User>('/api/auth/reset-password', data)
      .then(() => {
        navigate(routeNames.authentication.login, {
          replace: true,
        });
      })
      .catch(error => {
        AuthStore.setLoginError(error?.response?.data?.message as never);
      })
      .finally(() => {
        AuthStore.setLoading(false);
      });
  };

  const handleGoogleAuth = async (
    data: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
  ) => {
    AuthStore.setLoading(true);
    try {
      const googleResponse = await axios.get<GoogleProfile>(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`,
      );

      const googleProfile = googleResponse.data;

      const payload: SocialAuthProfile = {
        id: googleProfile.id,
        email: googleProfile.email,
        verified_email: googleProfile.verified_email,
        name: googleProfile.name,
        picture: googleProfile.picture,
      };

      const response = await post<User>('/api/auth/social-auth', {
        data: payload,
        type: AUTH_PROVIDER.GOOGLE,
      });

      AuthStore.setUserAndSocket(response);
      const url = replaceRouteParam(
        `${routeNames.dashboard.home}`,
        'workspaceId',
        AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
      );
      navigate(url, { replace: true });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify('error', error?.response?.data?.message);
      } else {
        notify('error', 'Something went wrong');
      }
      AuthStore.setLoginError(error);
    } finally {
      AuthStore.setLoading(false);
    }
  };

  const handleFacebookAuth = async (data: SocialAuthProfile) => {
    AuthStore.setLoading(true);
    try {
      const payload: SocialAuthProfile = {
        id: data.id,
        email: data.email,
        verified_email: true,
        name: data.name,
        picture: (data.picture as any)?.data?.url,
      };

      const response = await post<User>('/api/auth/social-auth', {
        data: payload,
        type: AUTH_PROVIDER.FACEBOOK,
      });
      AuthStore.setUserAndSocket(response);
      navigate(redirectTo, { replace: true });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify('error', error?.response?.data?.message);
      } else {
        notify('error', 'Something went wrong');
      }
      AuthStore.setLoginError(error);
    }
  };

  const logout = async () => {
    try {
      await destroy('/api/auth/logout');
      window.location.href = '/auth/login';
      AuthStore.setUserAndSocket(null);
    } catch (e) {
      AuthStore.setLoginError(e);
    }
  };

  const get_user_info = (id: string) => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_USER_INFO,
      { event_name: SOCKET_EVENT_NAMES.GET_USER_INFO, data: { id } },
      (response: SocketResponse<any>) => {
        if (response.data) {
          AuthStore.update_user_info(response.data);
        }
      },
    );
  };

  const updateUserAvatar = (id: string, profile_picture_url: string | null) => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.UPDATE_USER_AVATAR,
      {
        event_name: SOCKET_EVENT_NAMES.UPDATE_USER_AVATAR,
        data: { id, profile_picture_url },
      },
      (response: SocketResponse<any>) => {
        if (response.data) {
          AuthStore.update_user_info(response.data);
        }
      },
    );
  };

  return {
    login,
    logout,
    register,
    retryEvents,
    refreshToken,
    resetPassword,
    forgotPassword,
    refreshSocketToken,
    update_user_info_async,
    get_user_info,
    handleGoogleAuth,
    handleFacebookAuth,
    updateUserAvatar,
  };
};
