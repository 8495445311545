import { useState } from 'react';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import Text from '@/components/atoms/Text';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';

const EditForm = ({ data, handleEdit, handleSubmit }: any) => {
  const [formData, setFormData] = useState({
    user_name: data?.user_name || '',
    email: data?.email || '',
    country: data?.country || '',
    city: data?.city || '',
    job_title: data?.job_title || '',
    bio: data?.bio || '',
    twitter_followers: data?.twitter_followers || '',
    company_website: data?.company_website || '',
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fieldIcons: { [key: string]: string } = {
    user_name: 'ic:baseline-person',
    email: 'bi:mailbox2-flag',
    country: 'gis:globe-o',
    city: 'gis:globe-o',
    job_title: 'mdi:company',
    bio: 'oi:chat',
    twitter_followers: 'hugeicons:new-twitter',
    company_website: 'mdi:web',
  };

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between items-center w-full my-2'>
        <Text size='md' color='text-black'>
          Your Details
        </Text>
        <Button onClick={handleEdit} text='Close' />
      </div>

      <div className='mt-2'>
        {Object.keys(formData).map((key: any) => (
          <div key={key} className='flex items-center mb-4 border-b pb-2'>
            <span className='mr-2'>
              <Icon
                icon={fieldIcons[key] || 'ic:baseline-info'}
                width={20}
                height={20}
                color='#898D94'
              />
            </span>

            <Input
              name={key}
              value={formData[key as keyof typeof formData]}
              onChange={handleChange}
              placeholder={`Enter ${key.replace('_', ' ')}`}
              className='w-full'
            />
          </div>
        ))}
        <Button
          variant='outline'
          className='w-full h-[32px] mt-4'
          onClick={() => handleSubmit(formData)}
          text='Update Profile'
        />
      </div>
    </div>
  );
};

export default observer(EditForm);
