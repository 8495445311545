'use client';

import { useState } from 'react';
import { Check } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

const EMAIL_PLANS = [
  {
    value: 'starter',
    label: '500 active profiles and 5,000 email sends',
    price: 29,
  },
  {
    value: 'growth',
    label: '1,000 active profiles and 10,000 email sends',
    price: 49,
  },
  {
    value: 'business',
    label: '2,500 active profiles and 25,000 email sends',
    price: 99,
  },
  {
    value: 'enterprise',
    label: '5,000 active profiles and 50,000 email sends',
    price: 199,
  },
];

export default function EmailCredits() {
  const [enabled, setEnabled] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState('starter');

  return (
    <Card>
      <CardHeader>
        <div className='flex items-center justify-between'>
          <div className='space-y-1'>
            <CardTitle>Email Credits</CardTitle>
            <CardDescription className='text-md'>
              Manage your email sending capacity
            </CardDescription>
          </div>
          <div className='flex items-center space-x-2'>
            <Switch
              id='email-toggle'
              checked={enabled}
              onCheckedChange={setEnabled}
              className='data-[state=checked]:bg-[#033EB5]'
            />
            <Label htmlFor='email-toggle'>Active</Label>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {enabled ? (
          <div className='space-y-4'>
            <Select value={selectedPlan} onValueChange={setSelectedPlan}>
              <SelectTrigger className='w-full focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                <SelectValue placeholder='Select a plan' />
              </SelectTrigger>
              <SelectContent>
                {EMAIL_PLANS.map(plan => (
                  <SelectItem key={plan.value} value={plan.value}>
                    <div className='flex justify-between items-center gap-2'>
                      <span>{plan.label}</span>
                      <span className='text-muted-foreground'>
                        ${plan.price}/mo
                      </span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className='rounded-lg border bg-muted/50 p-4'>
              <div className='space-y-3'>
                <div className='flex items-center gap-2'>
                  <Check className='h-4 w-4 text-primary' />
                  <span className='text-md'>Automated email campaigns</span>
                </div>
                <div className='flex items-center gap-2'>
                  <Check className='h-4 w-4 text-primary' />
                  <span className='text-md'>Advanced analytics</span>
                </div>
                <div className='flex items-center gap-2'>
                  <Check className='h-4 w-4 text-primary' />
                  <span className='text-md'>Custom templates</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='text-center py-6 text-muted-foreground'>
            Enable email credits to manage your plan
          </div>
        )}
      </CardContent>
    </Card>
  );
}
