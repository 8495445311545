import Spinner from '@/components/atoms/Spinner';
import Modal from '@/components/atoms/modal';
import DeleteModal from '@/components/atoms/modal/DeleteModal';
import IntegrationListItem from '@/components/molecules/IntegrationListItem';
import { TelnyxForm } from '@/components/template/forms/TelnyxForm';
import { TwilioForm } from '@/components/template/forms/TwilioForm';
import { VonageForm } from '@/components/template/forms/VonageForm';
import { Card } from '@/components/ui/card';
import { BASE_URL } from '@/constants/index';
import { notify } from '@/helpers/index';
import { useIntegration } from '@/hooks/useIntegration';
import { AuthStore } from '@/state/AuthenticationStore';
import { integrationStore } from '@/state/IntegrationStore';
import {
  Integration,
  IntegrationName,
  IntegrationPayload,
} from '@/types/integration.types';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>(
    {} as Integration,
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    isModalOpen,
    closeModal,
    openModal,
    addIntegration,
    getIntegrations,
    isVonageModalOpen,
    setIsVonageModalOpen,
    setShowWebhookUrlModal,
    showWebhookUrlModal,
    disconnectIntegration,
    isTelnyxModalOpen,
    setIsTelnyxModalOpen,
  } = useIntegration();
  const user_workspace_info = AuthStore.user_workspace_info;
  const webhookUrl = `${BASE_URL}/api/sms/webhook/vonage/${user_workspace_info?.active_workspace?.workspace?._id}`;
  const workspaceIntegrations =
    user_workspace_info?.active_workspace?.workspace?.integrations
      ?.filter(Boolean)
      .map(integration => integration._id);

  const handleAddIntegration = (values: IntegrationPayload) => {
    addIntegration({
      ...values,
      integrationId: selectedIntegration?._id,
      integrationType: selectedIntegration?.name,
    });
  };

  const integrationHandlers: Record<
    IntegrationName,
    (payload: Integration) => void
  > = {
    Twilio: (payload: Integration) => {
      setSelectedIntegration(payload);

      if (workspaceIntegrations?.includes(payload._id)) {
        setShowConfirmationModal(true);
        return;
      }

      openModal();
    },
    Vonage: (payload: Integration) => {
      setSelectedIntegration(payload);

      if (workspaceIntegrations?.includes(payload._id)) {
        setShowConfirmationModal(true);
        return;
      }

      setIsVonageModalOpen(true);
    },
    Telnyx: (payload: Integration) => {
      setSelectedIntegration(payload);
      if (workspaceIntegrations?.includes(payload._id)) {
        setShowConfirmationModal(true);
        return;
      }
      setIsTelnyxModalOpen(true);
    },
    Outlook: () => {
      // TODO: Add Outlook integration
    },
    Google: () => {
      // TODO: Add Google integration
    },
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  if (integrationStore.loading) {
    return (
      <div className='min-h-[400px] flex justify-center items-center'>
        <Spinner size={30} />
      </div>
    );
  }

  return (
    <>
      <Card className='p-6'>
        <div className='flex flex-col bg-[#ffffff] overflow-y-auto rounded-[10px] space-y-4 my-[32px]'>
          {integrationStore.integrations?.map((item, index) => (
            <IntegrationListItem
              key={index}
              title={item.name}
              isConnected={workspaceIntegrations?.includes(item?._id)}
              isDisabled={item.isDisabled}
              handleClick={() => {
                integrationHandlers[item.name]?.(item);
              }}
              phrase={item.description}
              icon={item.logo}
              isLast={index === integrationStore.integrations?.length - 1}
              loading={
                item.name === selectedIntegration?.name &&
                integrationStore.creating
              }
            />
          ))}
        </div>
      </Card>
      <Modal
        show={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        title={`Twilio`}
        className='inline-block pt-4 max-w-[500px] max-h-[450px] overflow-auto sm:overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <TwilioForm handleTwilio={handleAddIntegration} />
      </Modal>

      <Modal
        show={isVonageModalOpen}
        openModal={() => setIsVonageModalOpen(true)}
        closeModal={() => setIsVonageModalOpen(false)}
        title='Vonage'
        className='inline-block pt-4 max-w-[500px] max-h-[450px] overflow-auto sm:overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <VonageForm handleVonage={handleAddIntegration} />
      </Modal>

      <Modal
        show={isTelnyxModalOpen}
        openModal={() => setIsTelnyxModalOpen(true)}
        closeModal={() => setIsTelnyxModalOpen(false)}
        title='Telnyx'
        className='inline-block pt-4 max-w-[500px] max-h-[450px] overflow-auto sm:overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <TelnyxForm handleTelnyx={handleAddIntegration} />
      </Modal>

      <Modal
        show={showWebhookUrlModal}
        openModal={() => setShowWebhookUrlModal(true)}
        closeModal={() => setShowWebhookUrlModal(false)}
        title='Vonage Webhook URL'
        className='inline-block pt-4 w-[500px] max-h-[450px] overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <div className='p-4'>
          <p className='text-[16px]'>
            Use this URL to receive incoming messages from{' '}
            {selectedIntegration?.name}. for help visit:{' '}
            <Link
              to={
                selectedIntegration.name === 'Vonage'
                  ? 'https://developer.vonage.com/en/messaging/sms/guides/inbound-sms'
                  : 'https://www.twilio.com/docs/sms'
              }
              className='text-[#0070F3] hover:underline'
              target='_blank'
            >
              {selectedIntegration?.name === 'Vonage'
                ? 'Vonage Guide'
                : 'Twilio Guide'}
            </Link>
          </p>
          <div className='flex items-center justify-between mt-4'>
            <input
              type='text'
              className='w-full border border-gray-300 rounded-[5px] p-2'
              value={webhookUrl}
              readOnly
            />
            <button
              className='bg-[#F3F4F6] rounded-[5px] px-4 py-2'
              onClick={() => {
                navigator.clipboard
                  .writeText(webhookUrl)
                  .then(() => {
                    notify('success', 'Copied to clipboard');
                  })
                  .catch(() => {
                    notify('error', 'Failed to copy to clipboard');
                  });
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </Modal>

      <DeleteModal
        show={showConfirmationModal}
        onDelete={() => {
          disconnectIntegration(selectedIntegration?._id);
          setShowConfirmationModal(false);
        }}
        setShow={setShowConfirmationModal}
        deleteTextButton='Disconnect'
        title='Disconnect Integration'
        content='Are you sure you want to disconnect this integration?'
      />
    </>
  );
};
export default observer(Services);
